import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink';
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import { isClient } from '../../utils/helpers'
import './ColourCharts.scss';

class ColourChartsPass extends Component {
  state = {
    modalContent: null
  };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }

  toggleModal = (event, object) => {
    event.preventDefault();
    this.setState({ modalContent: object });
  }

  render() {
    const { data } = this.props
    const { modalContent } = this.state
    const { wordpressPage } = data
    const { acf } = wordpressPage
    return (
      <div className="colour-content">
        {modalContent && 
        <div className="colour-modal">
          <div className="inner">
            <button className="close" onClick={(event) => this.toggleModal(event, null)} />
            <div className="image"><Image src={modalContent.image}/></div>
            <h4 className="colour-title"><strong>{modalContent.colourTitle.split('-')[1]}</strong><div className="colour-code">{modalContent.colourTitle.split('-')[0]}</div></h4>
          </div>
        </div>
        }
        {acf.charts && acf.charts.map((chart, index) =>
          <div className="group" key={index}>
            <GatsbyLink to={chart.groupDownload.source_url} target="_blank" className="action">{chart.chartDownloadLinkText}</GatsbyLink>
            <h4 className="group-title">{chart.groupTitle}</h4>
            <div className="colours">
              {chart.colours && chart.colours.map((colour, index) =>
                <div className="colour" key={index} onClick={(event) => this.toggleModal(event, colour)}>
                  <div className="image"><Image src={colour.image}/></div>
                  <h4 className="colour-title"><strong>{colour.colourTitle.split('-')[1]}</strong><div className="colour-code">{colour.colourTitle.split('-')[0]}</div></h4>
                </div>
              )}
            </div>
          </div>
        )}
        <RenderContent className="disclaimer" content={acf.disclaimer} />
      </div>
    )
  }
}

export const ColourCharts = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressPage(slug: {eq: "colour-charts"}) {
            acf {
              charts {
                colours {
                  colourTitle
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 350) {
                          ... GatsbyImageSharpFixed_noBase64
                        }
                      }
                    }
                  }
                }
                groupDownload {
                  source_url
                }
                groupTitle
                chartDownloadLinkText
              }
              disclaimer
            }
          }
        }
      `}
      render={data => <ColourChartsPass {...props} data={data} />}
    />
  );
}
